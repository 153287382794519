import "./index.less";

export default {

    render() {

        return (
            <div id="doc-main">
                <header>
                    <a href="https://zonasete.com.br" target="_blank">
                        <img src={require('@/assets/logo.svg')} alt="Zona Sete Vistorias" class="logo" />
                    </a>
                    <div class="title">
                        <h1>Zona Sete Vistorias</h1>
                        <h3>Página não encontrada</h3>
                    </div>
                </header>
                <a-divider />
                <div class="content">
                    <h1>404</h1>
                    <h2>Página não encontrada!</h2>
                    <a-button onClick={() => this.$router.push({ name: 'main' })}>Voltar para o início</a-button>
                </div>
                <footer class="rodape">
                    &copy; {(new Date()).getFullYear()} Todos os direitos reservados<br />
                    <a href="https://gxz.com.br" target="_blank">
                        <img src={require('@/assets/logo-gxz-light.png')} alt="GXZ" />
                    </a>
                </footer>
            </div>
        );
    }
}
